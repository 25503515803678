<template>
  <div class="cusBox">
    <el-form
      label-position="left"
      label-width="84px"
      ref="form"
      :model="realnameData"
      :rules="rules"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="真实姓名：" prop="name" :error="errorMessage?errorMessage.name:''">
            <el-input :disabled="userInfo.role !='personal'" v-model="realnameData.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="支付宝账号："
            prop="alipay_account"
            :error="errorMessage?errorMessage.alipay_account:''"
          >
            <el-input
              class="cus_border"
              :disabled="userInfo.role !='personal'"
              v-model="realnameData.alipay_account"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="身份证号："
            prop="id_number"
            :error="errorMessage?errorMessage.id_number:''"
          >
            <el-input :disabled="userInfo.role !='personal'" v-model="realnameData.id_number"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="cus_item" v-show="userInfo.role =='personal'">
        <el-checkbox v-model="checked">我已阅读并同意相关服务条款</el-checkbox>
        <el-button :disabled="!checked" type="primary" @click="onSubmit">提交实名认证</el-button>
      </div>
    </el-form>
    <div class="cus_dialog">
      <el-dialog :visible.sync="dialogForm" :show-close="false" :close-on-click-modal="false">
        <div class="cus_img" v-html="imgUrl"></div>
        <div class="textBox" style="width:100%">
          <div class="cus_text">
            1、请用支付宝扫描二维码进行扫脸认证。
            <br />2、扫脸成功后，点击下方“提交”按钮完成实名认证。
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button class="form_btn" type="primary" @click="handleStore">提 交</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api/personalapi";
export default {
  name: "realname",
  data() {
    var validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入真实姓名"));
      } else {
        callback();
      }
    };
    var validateAlipay = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入支付宝账号"));
      } else {
        callback();
      }
    };
    var validateId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入身份证号"));
      } else if (!(value.length === 18)) {
        callback(new Error("请输入正确的身份证号"));
      } else {
        callback();
      }
    };
    return {
      loading: true,
       realnameData: {
        name: '',
        alipay_account: '',
        id_number: ''
      },
      errorMessage: null,
      checked: false,
      dialogForm: false,
      imgUrl: "",
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        alipay_account: [{ validator: validateAlipay, trigger: "blur" }],
        id_number: [{ validator: validateId, trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  created(){
    this._getReal()
  },
  methods: {
    //获取账户设置数据
    async _getReal () {
      const data = await api.getRealApi();
      if (data.status_code === 200) {
        this.realnameData.name = data.message.name;
        this.realnameData.alipay_account = data.message.alipay_account;
        this.realnameData.id_number = data.message.id_number;
        this.loading = false
      }
    },
    onSubmit() {
      this.errorMessage = null;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this._certify();
        } else {
          return false;
        }
      });
    },
    //实名认证请求接口
    async _certify() {
      const { status_code, message } = await api.certifyApi(this.realnameData);
      if (status_code === 200) {
        this.dialogForm = true;
        this.imgUrl = message;
        this.remove();
      } else if (status_code === 422) {
        this.errorMessage = message;
      }
    },
    remove() {
      this.$nextTick(() => {
        this.$refs.form.resetFields();
        this.$refs.form.clearValidate();
      });
    },
    //扫描二维码后提交
    async handleStore() {
      const { status_code, message } = await api.certifyVerifyApi(
        this.realnameData
      );
      if (status_code === 200) {
        this.dialogForm = false;
        this.$store.commit("user/setUserInfo", {
          ...this.userInfo,
          role:'broker'
        });
        this.$setStorage("userInfo",{
          ...this.$getStorage("userInfo"),
          role:'broker'
        })
        this.loading = true
        this._getReal()
        this.$message({
          message: message,
          type: "success",
        });
      } else if (status_code === 400) {
        this.$message({
          message: message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cusBox /deep/ .el-form-item__label {
  line-height: 58px;
  padding: 0;
}
.cusBox /deep/ .el-form-item__content {
  line-height: 58px;
  height: 34px;
}
.cusBox /deep/.el-input__inner {
  height: 34px;
  line-height: 58px;
  border: none;
}
.cusBox /deep/.el-form-item {
  margin-bottom: 0px;
}
// .cus_border /deep/ .el-input__inner{
//     !important;
// }
.cusBox .cus_item /deep/ .el-form-item__content {
  margin-left: 0px !important;
  height: 58px;
}
.cus_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
}
.cus_item /deep/ .el-button--primary {
  width: 110px !important;
}
.cusBox /deep/ .el-button {
  border-radius: 2px;
  width: 110px;
}
.cusBox /deep/ .el-input.is-disabled .el-input__inner {
  background-color: #ffffff !important;
  color: #666666 !important;
  cursor: initial !important;
  // padding: 0;
}
.cus_img {
  text-align: center;
}
.cus_dialog /deep/ .el-dialog__body {
  padding: 20px 20px 10px 20px;
}
.cus_dialog /deep/ .el-button--primary {
  width: 100% !important;
}
.cus_dialog /deep/ .el-dialog__footer {
  text-align: center;
}
.textBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.cus_text {
  width: 100%;
  border: 1px solid #f39064;
  background-color: rgba(243, 144, 100, 0.4);
  padding: 5px;
  line-height: 21px;
}
</style>
