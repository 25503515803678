<template>
  <div class="relevant">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>相关条款</span>
      </div>
      <div style="margin-bottom: 20px;">
        <el-button type="text" class="titleText" @click="handleOpen('fuwu')">《“MCNopen”用户服务协议》</el-button>
      </div>
      <div>
        <el-button type="text" class="titleText" @click="handleOpen('jiankang')">《维护健康生态承诺书》</el-button>
      </div>
    </el-card>
    <el-dialog :title="title" :visible.sync="dialogVisible" :show-close="false">
      <div style="line-height:30px" v-html="content"></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import content from "../js/content";
import jiankang from "../js/jiankang";
export default {
  data() {
    return {
      dialogVisible: false,
      content,
      title:"",
      jiankang,
    };
  },
  created() {
    // this._getSign();
  },
  methods: {
    handleOpen(name) {
      if (name === "fuwu") {
        this.title = "《“MCNopen”用户服务协议》"
        this.content = content;
      } else if (name === "jiankang") {
        this.title = "《维护健康生态承诺书》"
        this.content = jiankang;
      }
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.relevant /deep/ .el-button--text {
  color: #333333;
}
.relevant /deep/ .el-dialog__header {
  text-align: center;
}
.relevant /deep/ .el-dialog__body {
  max-height: 566px;
  overflow: auto;
}
.relevant /deep/.el-dialog {
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
.titleText :hover {
  color: #D74D38;
  border-color: transparent;
  background-color: transparent;
}
</style>